// Must match the build from canvas-packages
export const fstPartyHostLessComponents = [
  "framer-motion",
  "plasmic-basic-components",
  "plasmic-nav",
  "react-awesome-reveal",
  "react-parallax-tilt",
  "plasmic-query",
  "react-scroll-parallax",
  "react-scroll-parallax-global",
  "react-slick",
  "react-youtube",
  "antd",
  "antd5",
  "lottie-react",
  "react-twitter-widgets",
  "plasmic-cms",
  "plasmic-embed-css",
  "commerce",
  "commerce-commercetools",
  "commerce-local",
  "commerce-saleor",
  "commerce-shopify",
  "commerce-swell",
  "plasmic-sanity-io",
  "plasmic-strapi",
  "airtable",
  "plasmic-content-stack",
  "plasmic-graphcms",
  "plasmic-contentful",
  "plasmic-wordpress",
  "plasmic-wordpress-graphql",
  "plasmic-chakra-ui",
  "plasmic-tabs",
  "plasmic-rich-components",
  "react-chartjs-2",
  "plasmic-link-preview",
  "react-quill",
  "tiptap",
  "loading-boundary",
  "radix-ui",
  "lodash",
  "uuid",
  "isomorphic-fetch",
  "fast-stringify",
  "marked",
  "pluralize",
  "copy-to-clipboard",
  "random",
  "faker",
  "nanoid",
  "tinycolor2",
  "zod",
  "immer",
  "md5",
  "axios",
  "semver",
  "date-fns",
  "papaparse",
  "jquery",
  "dayjs",
  "react-aria",
];
