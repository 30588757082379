// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import { Component, isKnownTplSlot, State } from "@/wab/classes";
import { StringPropEditor } from "@/wab/client/components/sidebar-tabs/ComponentProps/StringPropEditor";
import { PropEditorRow } from "@/wab/client/components/sidebar-tabs/PropEditorRow";
import { LabeledItemRow } from "@/wab/client/components/sidebar/sidebar-helpers";
import StyleSelect from "@/wab/client/components/style-controls/StyleSelect";
import {
  DefaultNewVariableProps,
  PlasmicNewVariable,
} from "@/wab/client/plasmic/plasmic_kit_state_management/PlasmicNewVariable";
import { StudioCtx } from "@/wab/client/studio-ctx/StudioCtx";
import { assert, ensure } from "@/wab/common";
import { isPageComponent } from "@/wab/components";
import { codeLit } from "@/wab/exprs";
import { wabTypeToPropType } from "@/wab/shared/code-components/code-components";
import { convertVariableTypeToWabType } from "@/wab/shared/core/model-util";
import { exprUsesDollarVars } from "@/wab/shared/eval/expression-parser";
import {
  getAccessTypeDisplayName,
  getDefaultValueForStateVariableType,
  StateAccessType,
  StateVariableType,
  STATE_VARIABLE_TYPES,
} from "@/wab/states";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { notification } from "antd";
import L from "lodash";
import { observer } from "mobx-react-lite";
import * as React from "react";

export interface NewVariableProps extends DefaultNewVariableProps {
  state: State;
  component: Component;
  studioCtx: StudioCtx;
  mode?: "new" | "edit";
  onCancel?: () => void;
  onConfirm?: () => void;
}

const VariableEditingForm = observer(
  function VariableEditingForm(
    {
      component,
      state,
      studioCtx,
      mode = "edit",
      onCancel,
      onConfirm,
      ...rest
    }: NewVariableProps,
    ref: HTMLElementRefOf<"div">
  ) {
    const vc = ensure(studioCtx.focusedOrFirstViewCtx(), "");
    assert(
      !isKnownTplSlot(component.tplTree),
      "slots can't be root of a component"
    );
    const StringEditor = React.useCallback(
      ({
        label,
        onChange,
        value,
        "data-plasmic-prop": dataPlasmicProp,
      }: {
        label: React.ReactNode;
        onChange: (val: string) => void;
        value: string;
        "data-plasmic-prop"?: string;
      }) => (
        <LabeledItemRow layout={"vertical"} label={label}>
          <StringPropEditor
            onChange={onChange}
            value={value}
            valueSetState={"isSet"}
            disabled={false}
            data-plasmic-prop={dataPlasmicProp}
          />
        </LabeledItemRow>
      ),
      []
    );

    const hasExternalAccess = state.accessType !== "private";
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onConfirm?.();
        }}
      >
        <PlasmicNewVariable
          root={{ ref }}
          {...rest}
          variableName={
            <StringEditor
              label={state.implicitState ? "External name" : "Name"}
              onChange={(val) =>
                studioCtx.change(({ success }) => {
                  if (val) {
                    studioCtx.tplMgr().renameParam(component, state.param, val);
                    if (state.onChangeParam) {
                      // Also rename the onChangeParam to be derived from
                      // the new name
                      studioCtx
                        .tplMgr()
                        .renameParam(
                          component,
                          state.onChangeParam,
                          `On ${val} Change`
                        );
                    }
                  }
                  return success();
                })
              }
              data-plasmic-prop={"variable-name"}
              value={state.param.variable.name}
            />
          }
          variableType={{
            props: {
              value: state.variableType,
              "data-plasmic-prop": "variable-type",
              onChange: (val) =>
                studioCtx.change(({ success }) => {
                  if (val && val !== state.variableType) {
                    studioCtx.siteOps().updateState(state, {
                      variableType: val as StateVariableType,
                    });
                    state.param.defaultExpr = codeLit(
                      getDefaultValueForStateVariableType(
                        val as StateVariableType
                      )
                    );
                  }
                  return success();
                }),
              children: STATE_VARIABLE_TYPES.filter(
                (stateType) => stateType !== "variant"
              ).map((stateType) => (
                <StyleSelect.Option value={stateType} key={stateType}>
                  {L.startCase(stateType)}
                </StyleSelect.Option>
              )),
            },
          }}
          isPageComponent={isPageComponent(component)}
          variableInitVal={
            <div
              style={{ position: "relative", width: "100%" }}
              className={"flex-fill"}
            >
              <PropEditorRow
                viewCtx={vc}
                tpl={component.tplTree}
                label={"Initial Value"}
                attr="initial-value"
                expr={state.param.defaultExpr ?? undefined}
                definedIndicator={{ source: "none" }}
                valueSetState={"isSet"}
                propType={wabTypeToPropType(
                  convertVariableTypeToWabType(
                    state.variableType as StateVariableType
                  )
                )}
                onChange={async (expr) => {
                  if (
                    state.accessType === "writable" &&
                    expr &&
                    exprUsesDollarVars(expr)
                  ) {
                    notification.error({
                      message: "Cannot set initial value",
                      description:
                        "Initial value for read-and-write state can not contain references to dynamic values that are available only in the current component context.",
                    });
                    return;
                  }
                  await studioCtx.change(({ success }) => {
                    state.param.defaultExpr = expr;
                    return success();
                  });
                }}
                layout={"vertical"}
                disableLinkToProp={true}
              />
            </div>
          }
          allowExternalAccess={{
            props: {
              isChecked: hasExternalAccess,
              onChange: (allow) => {
                void studioCtx.change(({ success }) => {
                  studioCtx.siteOps().updateState(state, {
                    accessType: allow ? "readonly" : "private",
                  });
                  return success();
                });
              },
              "data-test-id": "allow-external-access",
            },
          }}
          isExternal={hasExternalAccess}
          accessTypeSelect={{
            props: {
              "data-plasmic-prop": "access-type",
              value: state.accessType,
              onChange: async (val) => {
                if (
                  val === "writable" &&
                  state.param.defaultExpr &&
                  exprUsesDollarVars(state.param.defaultExpr)
                ) {
                  notification.error({
                    message: "Cannot set access type",
                    description:
                      "Variable initial value contains references to dynamic values. Remove those references to be able to set access type to read-write.",
                  });
                  return;
                }
                await studioCtx.change(({ success }) => {
                  if (val) {
                    studioCtx.siteOps().updateState(state, {
                      accessType: val as StateAccessType,
                    });
                  }
                  return success();
                });
              },
              children: (
                [
                  {
                    value: "readonly",
                    label: getAccessTypeDisplayName("readonly"),
                  },
                  {
                    value: "writable",
                    label: getAccessTypeDisplayName("writable"),
                  },
                ] as { value: StateAccessType; label: string }[]
              ).map(({ label, value }) => (
                <StyleSelect.Option value={value} key={value}>
                  {label}
                </StyleSelect.Option>
              )),
            },
          }}
          accessType={
            state.accessType === "private"
              ? "_private"
              : (state.accessType as Exclude<StateAccessType, "private">)
          }
          isImplicitState={!!state.tplNode}
          withFormButtons={mode === "new"}
          cancelButton={{
            onClick: () => onCancel?.(),
          }}
          confirmButton={{
            props: {
              htmlType: "submit",
              "data-test-id": "confirm",
            },
          }}
        />
      </form>
    );
  },
  { forwardRef: true }
);
export default VariableEditingForm;
