export const focusRing = "rgba(0, 145, 255, 0.5)";
export const iconSize = "16px";
export const listItemGap = "8px";
export const listItemHPadding = "16px";
export const listItemHeight = "32px";
export const listSectionHeaderHeight = "44px";
export const multiplayerColor01 = "rgb(48, 164, 108)";
export const multiplayerColor02 = "rgb(104, 221, 253)";
export const multiplayerColor03 = "rgb(142, 78, 198)";
export const multiplayerColor04 = "rgb(5, 162, 194)";
export const multiplayerColor05 = "rgb(214, 64, 159)";
export const multiplayerColor06 = "rgb(245, 217, 10)";
export const multiplayerColor07 = "rgb(247, 104, 8)";
export const spectrumBlue15 = "rgb(5, 33, 77)";
export const spectrumBlue60 = "rgb(69, 153, 255)";
export const spectrumBlue95 = "rgb(231, 243, 255)";
export const spectrumCoral40 = "rgb(224,62,40)rgb(224,62,40)rgb(224,62,40)rgb(224,62,40)";
export const spectrumCoral50 = "rgb(245,83,61)rgb(245,83,61)rgb(245,83,61)";
export const spectrumCoral80 = "rgb(255, 186, 176)";
export const spectrumCoral95 = "rgb(255,230,227)rgb(255,230,227)rgb(255,230,227)";
export const spectrumCyan40 = "rgb(0, 110, 173)";
export const spectrumCyan50 = "rgb(0,139,208)rgb(0,139,208)";
export const spectrumCyan55 = "rgb(0, 153, 230)";
export const spectrumCyan60 = "rgb(4,164,244)#04A4F4rgb(4,164,244)rgb(4,164,244)";
export const spectrumCyan70 = "rgb(79, 196, 251)";
export const spectrumCyan95 = "rgb(224,247,255)rgb(224,247,255)";
export const spectrumGray00 = "rgb(5,5,5)rgb(5,5,5)";
export const spectrumGray05 = "rgb(14,15,15)rgb(14,15,15)";
export const spectrumGray100 = "rgb(245,247,250)rgb(245,247,250)rgb(245,247,250)rgb(245,247,250)";
export const spectrumGray20 = "rgb(50,52,54)#323436rgb(50,52,54)rgb(50,52,54)rgb(50,52,54)";
export const spectrumGray30 = "rgb(74,76,79)rgb(74,76,79)";
export const spectrumGray50 = "rgb(122,125,130)rgb(122,125,130)rgb(122,125,130)";
export const spectrumGray55 = "rgb(138, 141, 145)";
export const spectrumGray60 = "rgb(150,153,158)rgb(150,153,158)rgb(150,153,158)rgb(150,153,158)";
export const spectrumGray65 = "rgb(164,167,171)rgb(164,167,171)rgb(164,167,171)rgb(164,167,171)";
export const spectrumGray80 = "rgb(201,204,209)rgb(201,204,209)";
export const spectrumGray85 = "rgb(215, 218, 222)";
export const spectrumGray90 = "rgb(228,230,235)rgb(228,230,235)rgb(228,230,235)";
export const spectrumGray95 = "rgb(240,242,245)rgb(240,242,245)rgb(240,242,245)rgb(240,242,245)rgb(240,242,245)rgb(240,242,245)";
export const spectrumGreen50 = "rgb(49, 162, 76)";
export const spectrumGreen60 = "rgb(69, 189, 98)";
export const spectrumRed35 = "rgb(228,30,63)rgb(228,30,63)";
export const spectrumRed40 = "rgb(240, 40, 73)";
export const spectrumYellow30 = "rgb(181, 84, 0)";
export const spectrumYellow55 = "rgb(241, 168, 23)";
export const spectrumYellow60 = "rgb(247, 185, 40)";
export const spectrumYellow95 = "rgb(255,249,209)rgb(255,249,209)rgb(255,249,209)";
export const tabItemSelectedBg = "rgb(235, 246, 255)";
export const tabItemSelectedForeground = "rgb(0, 145, 255)";
export const activeBg = "rgb(237, 246, 255)";
export const activeFg = "rgb(0, 145, 255)";
export const black = "rgb(0, 0, 0)";
export const blackA1 = "rgba(0, 0, 0, 0.01)";
export const blackA10 = "rgba(0, 0, 0, 0.48)";
export const blackA11 = "rgba(0, 0, 0, 0.57)";
export const blackA12 = "rgba(0, 0, 0, 0.91)";
export const blackA2 = "rgba(0, 0, 0, 0.03)";
export const blackA3 = "rgba(0, 0, 0, 0.05)";
export const blackA4 = "rgba(0, 0, 0, 0.07)";
export const blackA5 = "rgba(0, 0, 0, 0.09)";
export const blackA6 = "rgba(0, 0, 0, 0.11)";
export const blackA7 = "rgba(0, 0, 0, 0.14)";
export const blackA8 = "rgba(0, 0, 0, 0.22)";
export const blackA9 = "rgba(0, 0, 0, 0.44)";
export const blue1 = "rgb(251, 253, 255)";
export const blue10 = "rgb(0, 129, 241)";
export const blue11 = "rgb(0, 106, 220)";
export const blue12 = "rgb(0, 37, 77)";
export const blue2 = "rgb(245, 250, 255)";
export const blue3 = "rgb(237, 246, 255)";
export const blue4 = "rgb(225, 240, 255)";
export const blue5 = "rgb(206, 231, 254)";
export const blue6 = "rgb(183, 217, 248)";
export const blue7 = "rgb(150, 199, 242)";
export const blue8 = "rgb(94, 176, 239)";
export const blue9 = "rgb(0, 145, 255)";
export const blueDark1 = "rgb(15, 23, 32)";
export const blueDark10 = "rgb(54, 158, 255)";
export const blueDark11 = "rgb(82, 169, 255)";
export const blueDark12 = "rgb(234, 246, 255)";
export const blueDark2 = "rgb(15, 27, 45)";
export const blueDark3 = "rgb(16, 36, 62)";
export const blueDark4 = "rgb(16, 42, 76)";
export const blueDark5 = "rgb(15, 48, 88)";
export const blueDark6 = "rgb(13, 56, 104)";
export const blueDark7 = "rgb(10, 68, 129)";
export const blueDark8 = "rgb(9, 84, 165)";
export const blueDark9 = "rgb(0, 145, 255)";
export const borderColor = "rgb(224, 224, 224)";
export const brown1 = "rgb(254, 253, 252)";
export const brown10 = "rgb(160, 118, 83)";
export const brown11 = "rgb(136, 99, 73)";
export const brown12 = "rgb(63, 44, 34)";
export const brown2 = "rgb(252, 249, 246)";
export const brown3 = "rgb(248, 241, 234)";
export const brown4 = "rgb(244, 233, 221)";
export const brown5 = "rgb(239, 221, 204)";
export const brown6 = "rgb(232, 205, 181)";
export const brown7 = "rgb(221, 184, 150)";
export const brown8 = "rgb(208, 158, 114)";
export const brown9 = "rgb(173, 127, 88)";
export const brownDark1 = "rgb(25, 21, 19)";
export const brownDark10 = "rgb(189, 139, 96)";
export const brownDark11 = "rgb(219, 161, 110)";
export const brownDark12 = "rgb(250, 240, 229)";
export const brownDark2 = "rgb(34, 24, 19)";
export const brownDark3 = "rgb(46, 32, 26)";
export const brownDark4 = "rgb(54, 38, 30)";
export const brownDark5 = "rgb(62, 44, 34)";
export const brownDark6 = "rgb(62, 44, 34)";
export const brownDark7 = "rgb(92, 67, 50)";
export const brownDark8 = "rgb(119, 89, 64)";
export const brownDark9 = "rgb(173, 127, 88)";
export const crimson1 = "rgb(255, 252, 253)";
export const crimson10 = "rgb(224, 49, 119)";
export const crimson11 = "rgb(211, 30, 102)";
export const crimson12 = "rgb(61, 13, 29)";
export const crimson2 = "rgb(255, 247, 251)";
export const crimson3 = "rgb(254, 239, 246)";
export const crimson4 = "rgb(252, 229, 240)";
export const crimson5 = "rgb(249, 216, 231)";
export const crimson6 = "rgb(244, 198, 219)";
export const crimson7 = "rgb(237, 173, 200)";
export const crimson8 = "rgb(229, 143, 177)";
export const crimson9 = "rgb(233, 61, 130)";
export const crimsonDark1 = "rgb(29, 20, 24)";
export const crimsonDark10 = "rgb(240, 79, 136)";
export const crimsonDark11 = "rgb(247, 97, 144)";
export const crimsonDark12 = "rgb(254, 236, 244)";
export const crimsonDark2 = "rgb(39, 20, 28)";
export const crimsonDark3 = "rgb(60, 24, 39)";
export const crimsonDark4 = "rgb(72, 26, 45)";
export const crimsonDark5 = "rgb(84, 27, 51)";
export const crimsonDark6 = "rgb(100, 29, 59)";
export const crimsonDark7 = "rgb(128, 29, 69)";
export const crimsonDark8 = "rgb(174, 25, 85)";
export const crimsonDark9 = "rgb(233, 61, 130)";
export const cyan1 = "rgb(250, 253, 254)";
export const cyan10 = "rgb(8, 148, 179)";
export const cyan11 = "rgb(12, 119, 146)";
export const cyan12 = "rgb(4, 49, 60)";
export const cyan2 = "rgb(242, 252, 253)";
export const cyan3 = "rgb(231, 249, 251)";
export const cyan4 = "rgb(216, 243, 246)";
export const cyan5 = "rgb(196, 234, 239)";
export const cyan6 = "rgb(170, 222, 230)";
export const cyan7 = "rgb(132, 205, 218)";
export const cyan8 = "rgb(61, 185, 207)";
export const cyan9 = "rgb(5, 162, 194)";
export const cyanDark1 = "rgb(7, 25, 29)";
export const cyanDark10 = "rgb(0, 177, 204)";
export const cyanDark11 = "rgb(0, 194, 215)";
export const cyanDark12 = "rgb(225, 248, 250)";
export const cyanDark2 = "rgb(6, 30, 36)";
export const cyanDark3 = "rgb(7, 40, 48)";
export const cyanDark4 = "rgb(7, 48, 59)";
export const cyanDark5 = "rgb(7, 56, 68)";
export const cyanDark6 = "rgb(6, 65, 80)";
export const cyanDark7 = "rgb(4, 80, 99)";
export const cyanDark8 = "rgb(0, 100, 125)";
export const cyanDark9 = "rgb(5, 162, 194)";
export const enterpriseBackground = "rgb(22, 22, 21)";
export const gold1 = "rgb(253, 253, 252)";
export const gold10 = "rgb(140, 121, 93)";
export const gold11 = "rgb(119, 103, 80)";
export const gold12 = "rgb(59, 53, 43)";
export const gold2 = "rgb(251, 249, 242)";
export const gold3 = "rgb(245, 242, 233)";
export const gold4 = "rgb(238, 234, 221)";
export const gold5 = "rgb(229, 223, 208)";
export const gold6 = "rgb(218, 209, 189)";
export const gold7 = "rgb(203, 189, 164)";
export const gold8 = "rgb(184, 163, 131)";
export const gold9 = "rgb(151, 131, 101)";
export const goldDark1 = "rgb(23, 22, 19)";
export const goldDark10 = "rgb(165, 144, 113)";
export const goldDark11 = "rgb(191, 168, 136)";
export const goldDark12 = "rgb(247, 244, 231)";
export const goldDark2 = "rgb(28, 26, 21)";
export const goldDark3 = "rgb(38, 35, 28)";
export const goldDark4 = "rgb(46, 42, 33)";
export const goldDark5 = "rgb(53, 48, 38)";
export const goldDark6 = "rgb(62, 56, 44)";
export const goldDark7 = "rgb(80, 71, 55)";
export const goldDark8 = "rgb(107, 93, 72)";
export const goldDark9 = "rgb(151, 131, 101)";
export const grass1 = "rgb(251, 254, 251)";
export const grass10 = "rgb(61, 154, 80)";
export const grass11 = "rgb(41, 124, 59)";
export const grass12 = "rgb(27, 49, 30)";
export const grass2 = "rgb(243, 252, 243)";
export const grass3 = "rgb(235, 249, 235)";
export const grass4 = "rgb(223, 243, 223)";
export const grass5 = "rgb(206, 235, 207)";
export const grass6 = "rgb(183, 223, 186)";
export const grass7 = "rgb(151, 207, 156)";
export const grass8 = "rgb(101, 186, 117)";
export const grass9 = "rgb(70, 167, 88)";
export const grassDark1 = "rgb(13, 25, 18)";
export const grassDark10 = "rgb(85, 180, 103)";
export const grassDark11 = "rgb(99, 193, 116)";
export const grassDark12 = "rgb(229, 251, 235)";
export const grassDark2 = "rgb(15, 30, 19)";
export const grassDark3 = "rgb(19, 40, 25)";
export const grassDark4 = "rgb(22, 48, 29)";
export const grassDark5 = "rgb(25, 57, 33)";
export const grassDark6 = "rgb(29, 68, 39)";
export const grassDark7 = "rgb(36, 85, 48)";
export const grassDark8 = "rgb(47, 110, 59)";
export const grassDark9 = "rgb(70, 167, 88)";
export const green1 = "rgb(251, 254, 252)";
export const green10 = "rgb(41, 151, 100)";
export const green11 = "rgb(24, 121, 78)";
export const green12 = "rgb(21, 50, 38)";
export const green2 = "rgb(242, 252, 245)";
export const green3 = "rgb(233, 249, 238)";
export const green4 = "rgb(221, 243, 228)";
export const green5 = "rgb(204, 235, 215)";
export const green6 = "rgb(180, 223, 196)";
export const green7 = "rgb(146, 206, 172)";
export const green8 = "rgb(91, 185, 140)";
export const green9 = "rgb(48, 164, 108)";
export const greenDark1 = "rgb(13, 25, 18)";
export const greenDark10 = "rgb(60, 177, 121)";
export const greenDark11 = "rgb(76, 195, 138)";
export const greenDark12 = "rgb(229, 251, 235)";
export const greenDark2 = "rgb(12, 31, 23)";
export const greenDark3 = "rgb(15, 41, 30)";
export const greenDark4 = "rgb(17, 49, 35)";
export const greenDark5 = "rgb(19, 57, 41)";
export const greenDark6 = "rgb(22, 68, 48)";
export const greenDark7 = "rgb(27, 84, 58)";
export const greenDark8 = "rgb(35, 110, 74)";
export const greenDark9 = "rgb(48, 164, 108)";
export const h1FontSize = "72px";
export const h2FontSize = "48px";
export const h3FontSize = "32px";
export const h4FontSize = "24px";
export const h5FontSize = "20px";
export const h6FontSize = "16px";
export const indigo1 = "rgb(253, 253, 254)";
export const indigo10 = "rgb(58, 92, 204)";
export const indigo11 = "rgb(52, 81, 178)";
export const indigo12 = "rgb(16, 29, 70)";
export const indigo2 = "rgb(248, 250, 255)";
export const indigo3 = "rgb(240, 244, 255)";
export const indigo4 = "rgb(230, 237, 254)";
export const indigo5 = "rgb(217, 226, 252)";
export const indigo6 = "rgb(198, 212, 249)";
export const indigo7 = "rgb(174, 192, 245)";
export const indigo8 = "rgb(141, 164, 239)";
export const indigo9 = "rgb(62, 99, 221)";
export const indigoDark1 = "rgb(19, 22, 32)";
export const indigoDark10 = "rgb(83, 115, 231)";
export const indigoDark11 = "rgb(132, 157, 255)";
export const indigoDark12 = "rgb(238, 241, 253)";
export const indigoDark2 = "rgb(21, 25, 45)";
export const indigoDark3 = "rgb(25, 33, 64)";
export const indigoDark4 = "rgb(28, 39, 79)";
export const indigoDark5 = "rgb(31, 44, 92)";
export const indigoDark6 = "rgb(34, 52, 110)";
export const indigoDark7 = "rgb(39, 62, 137)";
export const indigoDark8 = "rgb(47, 78, 178)";
export const indigoDark9 = "rgb(62, 99, 221)";
export const inheritedBorder = "rgba(0, 0, 0, 0.14)";
export const mediumFontSize = "16px";
export const neutral500 = "rgb(102, 109, 128)";
export const neutral600 = "rgb(54, 57, 74)";
export const neutralPrimary = "rgb(27, 27, 24)";
export const neutralSecondary = "rgb(112, 111, 108)";
export const normalFontSize = "12px";
export const orange1 = "rgb(254, 252, 251)";
export const orange10 = "rgb(237, 95, 0)";
export const orange11 = "rgb(189, 75, 0)";
export const orange12 = "rgb(69, 30, 17)";
export const orange2 = "rgb(254, 248, 244)";
export const orange3 = "rgb(255, 241, 231)";
export const orange4 = "rgb(255, 232, 215)";
export const orange5 = "rgb(255, 220, 195)";
export const orange6 = "rgb(255, 204, 167)";
export const orange7 = "rgb(255, 179, 129)";
export const orange8 = "rgb(250, 147, 78)";
export const orange9 = "rgb(247, 104, 8)";
export const orangeDark1 = "rgb(31, 18, 6)";
export const orangeDark10 = "rgb(255, 128, 43)";
export const orangeDark11 = "rgb(255, 139, 62)";
export const orangeDark12 = "rgb(254, 234, 221)";
export const orangeDark2 = "rgb(43, 20, 0)";
export const orangeDark3 = "rgb(57, 26, 3)";
export const orangeDark4 = "rgb(68, 31, 4)";
export const orangeDark5 = "rgb(79, 35, 5)";
export const orangeDark6 = "rgb(95, 42, 6)";
export const orangeDark7 = "rgb(118, 50, 5)";
export const orangeDark8 = "rgb(148, 62, 0)";
export const orangeDark9 = "rgb(247, 104, 8)";
export const panelBorder = "rgb(228,230,235)#E4E6EB";
export const pink1 = "rgb(255, 252, 254)";
export const pink10 = "rgb(210, 49, 151)";
export const pink11 = "rgb(205, 29, 141)";
export const pink12 = "rgb(59, 10, 42)";
export const pink2 = "rgb(255, 247, 252)";
export const pink3 = "rgb(254, 238, 248)";
export const pink4 = "rgb(252, 229, 243)";
export const pink5 = "rgb(249, 216, 236)";
export const pink6 = "rgb(243, 198, 226)";
export const pink7 = "rgb(236, 173, 212)";
export const pink8 = "rgb(227, 142, 195)";
export const pink9 = "rgb(214, 64, 159)";
export const pinkDark1 = "rgb(31, 18, 27)";
export const pinkDark10 = "rgb(227, 75, 169)";
export const pinkDark11 = "rgb(246, 92, 182)";
export const pinkDark12 = "rgb(254, 235, 247)";
export const pinkDark2 = "rgb(39, 20, 33)";
export const pinkDark3 = "rgb(58, 24, 47)";
export const pinkDark4 = "rgb(69, 26, 55)";
export const pinkDark5 = "rgb(80, 27, 63)";
export const pinkDark6 = "rgb(96, 29, 72)";
export const pinkDark7 = "rgb(122, 29, 90)";
export const pinkDark8 = "rgb(167, 24, 115)";
export const pinkDark9 = "rgb(214, 64, 159)";
export const plum1 = "rgb(254, 252, 255)";
export const plum10 = "rgb(164, 60, 180)";
export const plum11 = "rgb(156, 43, 173)";
export const plum12 = "rgb(52, 12, 59)";
export const plum2 = "rgb(255, 248, 255)";
export const plum3 = "rgb(252, 239, 252)";
export const plum4 = "rgb(249, 229, 249)";
export const plum5 = "rgb(243, 217, 244)";
export const plum6 = "rgb(235, 200, 237)";
export const plum7 = "rgb(223, 175, 227)";
export const plum8 = "rgb(207, 145, 216)";
export const plum9 = "rgb(171, 74, 186)";
export const plumDark1 = "rgb(29, 19, 29)";
export const plumDark10 = "rgb(189, 84, 198)";
export const plumDark11 = "rgb(216, 100, 216)";
export const plumDark12 = "rgb(251, 236, 252)";
export const plumDark2 = "rgb(37, 20, 37)";
export const plumDark3 = "rgb(52, 26, 52)";
export const plumDark4 = "rgb(62, 29, 64)";
export const plumDark5 = "rgb(72, 33, 75)";
export const plumDark6 = "rgb(84, 38, 88)";
export const plumDark7 = "rgb(105, 45, 111)";
export const plumDark8 = "rgb(136, 56, 148)";
export const plumDark9 = "rgb(171, 74, 186)";
export const purple1 = "rgb(254, 252, 254)";
export const purple10 = "rgb(132, 69, 188)";
export const purple11 = "rgb(121, 58, 175)";
export const purple12 = "rgb(43, 14, 68)";
export const purple2 = "rgb(253, 250, 255)";
export const purple3 = "rgb(249, 241, 254)";
export const purple4 = "rgb(243, 231, 252)";
export const purple5 = "rgb(237, 219, 249)";
export const purple6 = "rgb(227, 204, 244)";
export const purple7 = "rgb(211, 180, 237)";
export const purple8 = "rgb(190, 147, 228)";
export const purple9 = "rgb(142, 78, 198)";
export const purpleDark1 = "rgb(27, 20, 29)";
export const purpleDark10 = "rgb(157, 91, 210)";
export const purpleDark11 = "rgb(191, 122, 240)";
export const purpleDark12 = "rgb(247, 236, 252)";
export const purpleDark2 = "rgb(34, 21, 39)";
export const purpleDark3 = "rgb(48, 26, 58)";
export const purpleDark4 = "rgb(58, 30, 72)";
export const purpleDark5 = "rgb(67, 33, 85)";
export const purpleDark6 = "rgb(78, 38, 103)";
export const purpleDark7 = "rgb(95, 45, 132)";
export const purpleDark8 = "rgb(121, 56, 178)";
export const purpleDark9 = "rgb(142, 78, 198)";
export const red1 = "rgb(255, 252, 252)";
export const red10 = "rgb(220, 61, 67)";
export const red11 = "rgb(205, 43, 49)";
export const red12 = "rgb(56, 19, 22)";
export const red2 = "rgb(255, 248, 248)";
export const red3 = "rgb(255, 239, 239)";
export const red4 = "rgb(255, 229, 229)";
export const red5 = "rgb(253, 216, 216)";
export const red6 = "rgb(249, 198, 198)";
export const red7 = "rgb(243, 174, 175)";
export const red8 = "rgb(235, 144, 145)";
export const red9 = "rgb(229, 72, 77)";
export const redDark1 = "rgb(31, 19, 21)";
export const redDark10 = "rgb(242, 85, 90)";
export const redDark11 = "rgb(255, 99, 105)";
export const redDark12 = "rgb(254, 236, 238)";
export const redDark2 = "rgb(41, 20, 21)";
export const redDark3 = "rgb(60, 24, 26)";
export const redDark4 = "rgb(72, 26, 29)";
export const redDark5 = "rgb(84, 27, 31)";
export const redDark6 = "rgb(103, 30, 34)";
export const redDark7 = "rgb(130, 32, 37)";
export const redDark8 = "rgb(170, 36, 41)";
export const redDark9 = "rgb(229, 72, 77)";
export const sand1 = "rgb(253, 253, 252)";
export const sand10 = "rgb(134, 134, 130)";
export const sand11 = "rgb(112, 111, 108)";
export const sand12 = "rgb(27, 27, 24)";
export const sand2 = "rgb(249, 249, 248)";
export const sand3 = "rgb(243, 243, 242)";
export const sand4 = "rgb(238, 238, 236)";
export const sand5 = "rgb(233, 233, 230)";
export const sand6 = "rgb(227, 227, 224)";
export const sand7 = "rgb(219, 219, 215)";
export const sand8 = "rgb(200, 199, 193)";
export const sand9 = "rgb(144, 144, 140)";
export const sandDark1 = "rgb(22, 22, 21)";
export const sandDark10 = "rgb(127, 126, 119)";
export const sandDark11 = "rgb(161, 160, 154)";
export const sandDark12 = "rgb(237, 237, 236)";
export const sandDark2 = "rgb(28, 28, 26)";
export const sandDark3 = "rgb(35, 35, 32)";
export const sandDark4 = "rgb(40, 40, 38)";
export const sandDark5 = "rgb(46, 46, 43)";
export const sandDark6 = "rgb(53, 52, 49)";
export const sandDark7 = "rgb(62, 62, 58)";
export const sandDark8 = "rgb(81, 80, 75)";
export const sandDark9 = "rgb(113, 112, 105)";
export const sky1 = "rgb(249, 254, 255)";
export const sky10 = "rgb(95, 212, 244)";
export const sky11 = "rgb(0, 120, 161)";
export const sky12 = "rgb(0, 50, 66)";
export const sky2 = "rgb(241, 252, 255)";
export const sky3 = "rgb(228, 249, 255)";
export const sky4 = "rgb(213, 244, 253)";
export const sky5 = "rgb(193, 236, 249)";
export const sky6 = "rgb(164, 223, 241)";
export const sky7 = "rgb(121, 207, 234)";
export const sky8 = "rgb(46, 189, 229)";
export const sky9 = "rgb(104, 221, 253)";
export const skyDark1 = "rgb(12, 24, 32)";
export const skyDark10 = "rgb(138, 232, 255)";
export const skyDark11 = "rgb(46, 200, 238)";
export const skyDark12 = "rgb(234, 248, 255)";
export const skyDark2 = "rgb(7, 29, 42)";
export const skyDark3 = "rgb(8, 38, 54)";
export const skyDark4 = "rgb(8, 45, 65)";
export const skyDark5 = "rgb(8, 53, 76)";
export const skyDark6 = "rgb(8, 62, 89)";
export const skyDark7 = "rgb(6, 75, 107)";
export const skyDark8 = "rgb(0, 93, 133)";
export const skyDark9 = "rgb(104, 221, 253)";
export const teal1 = "rgb(250, 254, 253)";
export const teal10 = "rgb(14, 152, 136)";
export const teal11 = "rgb(6, 122, 111)";
export const teal12 = "rgb(16, 48, 43)";
export const teal2 = "rgb(241, 252, 250)";
export const teal3 = "rgb(231, 249, 245)";
export const teal4 = "rgb(217, 243, 238)";
export const teal5 = "rgb(199, 235, 229)";
export const teal6 = "rgb(175, 223, 215)";
export const teal7 = "rgb(141, 206, 195)";
export const teal8 = "rgb(83, 185, 171)";
export const teal9 = "rgb(18, 165, 148)";
export const tealDark1 = "rgb(9, 25, 21)";
export const tealDark10 = "rgb(16, 179, 163)";
export const tealDark11 = "rgb(10, 197, 179)";
export const tealDark12 = "rgb(225, 250, 244)";
export const tealDark2 = "rgb(4, 32, 27)";
export const tealDark3 = "rgb(6, 41, 35)";
export const tealDark4 = "rgb(7, 49, 43)";
export const tealDark5 = "rgb(8, 57, 50)";
export const tealDark6 = "rgb(9, 68, 60)";
export const tealDark7 = "rgb(11, 84, 74)";
export const tealDark8 = "rgb(12, 109, 98)";
export const tealDark9 = "rgb(18, 165, 148)";
export const textInherited = "rgb(112, 111, 108)";
export const textSet = "rgb(27, 27, 24)";
export const textUnset = "rgb(144, 144, 140)";
export const textboxdisabled = "rgba(27, 27, 24, 0.5)";
export const textboxempty = "rgb(112, 111, 108)";
export const tomato1 = "rgb(255, 252, 252)";
export const tomato10 = "rgb(219, 67, 36)";
export const tomato11 = "rgb(202, 50, 20)";
export const tomato12 = "rgb(52, 23, 17)";
export const tomato2 = "rgb(255, 248, 247)";
export const tomato3 = "rgb(255, 240, 238)";
export const tomato4 = "rgb(255, 230, 226)";
export const tomato5 = "rgb(253, 216, 211)";
export const tomato6 = "rgb(250, 199, 190)";
export const tomato7 = "rgb(243, 176, 162)";
export const tomato8 = "rgb(234, 146, 128)";
export const tomato9 = "rgb(229, 77, 46)";
export const tomatoDark1 = "rgb(29, 20, 18)";
export const tomatoDark10 = "rgb(236, 94, 65)";
export const tomatoDark11 = "rgb(241, 106, 80)";
export const tomatoDark12 = "rgb(254, 239, 236)";
export const tomatoDark2 = "rgb(42, 20, 16)";
export const tomatoDark3 = "rgb(59, 24, 19)";
export const tomatoDark4 = "rgb(72, 26, 20)";
export const tomatoDark5 = "rgb(84, 28, 21)";
export const tomatoDark6 = "rgb(101, 32, 22)";
export const tomatoDark7 = "rgb(127, 35, 21)";
export const tomatoDark8 = "rgb(164, 42, 18)";
export const tomatoDark9 = "rgb(229, 77, 46)";
export const transparent = "rgba(255, 255, 255, 0)";
export const violet1 = "rgb(253, 252, 254)";
export const violet10 = "rgb(100, 79, 193)";
export const violet11 = "rgb(87, 70, 175)";
export const violet12 = "rgb(32, 19, 75)";
export const violet2 = "rgb(251, 250, 255)";
export const violet3 = "rgb(245, 242, 255)";
export const violet4 = "rgb(237, 233, 254)";
export const violet5 = "rgb(228, 222, 252)";
export const violet6 = "rgb(215, 207, 249)";
export const violet7 = "rgb(196, 184, 243)";
export const violet8 = "rgb(170, 153, 236)";
export const violet9 = "rgb(110, 86, 207)";
export const violetDark1 = "rgb(23, 21, 31)";
export const violetDark10 = "rgb(124, 102, 220)";
export const violetDark11 = "rgb(158, 140, 252)";
export const violetDark12 = "rgb(241, 238, 254)";
export const violetDark2 = "rgb(28, 23, 43)";
export const violetDark3 = "rgb(37, 30, 64)";
export const violetDark4 = "rgb(44, 34, 80)";
export const violetDark5 = "rgb(50, 39, 95)";
export const violetDark6 = "rgb(57, 44, 114)";
export const violetDark7 = "rgb(68, 53, 146)";
export const violetDark8 = "rgb(88, 66, 195)";
export const violetDark9 = "rgb(110, 86, 207)";
export const white = "#FFFFFFrgb(255,255,255)rgb(255,255,255)rgb(255,255,255)rgb(255,255,255)rgb(255,255,255)";
export const whiteA1 = "rgba(0, 0, 0, 0)";
export const whiteA10 = "rgba(255, 255, 255, 0.45)";
export const whiteA11 = "rgba(255, 255, 255, 0.59)";
export const whiteA12 = "rgba(255, 255, 255, 0.92)";
export const whiteA2 = "rgba(255, 255, 255, 0.01)";
export const whiteA3 = "rgba(255, 255, 255, 0.03)";
export const whiteA4 = "rgba(255, 255, 255, 0.06)";
export const whiteA5 = "rgba(255, 255, 255, 0.09)";
export const whiteA6 = "rgba(255, 255, 255, 0.12)";
export const whiteA7 = "rgba(255, 255, 255, 0.18)";
export const whiteA8 = "rgba(255, 255, 255, 0.25)";
export const whiteA9 = "rgba(255, 255, 255, 0.39)";
export const yellow1 = "rgb(253, 253, 249)";
export const yellow10 = "rgb(247, 206, 0)";
export const yellow11 = "rgb(148, 104, 0)";
export const yellow12 = "rgb(53, 41, 15)";
export const yellow2 = "rgb(255, 252, 232)";
export const yellow3 = "rgb(255, 251, 209)";
export const yellow4 = "rgb(255, 248, 187)";
export const yellow5 = "rgb(254, 242, 164)";
export const yellow6 = "rgb(249, 230, 140)";
export const yellow7 = "rgb(239, 211, 108)";
export const yellow8 = "rgb(235, 188, 0)";
export const yellow9 = "rgb(245, 217, 10)";
export const yellowDark1 = "rgb(28, 21, 0)";
export const yellowDark10 = "rgb(255, 239, 92)";
export const yellowDark11 = "rgb(240, 192, 0)";
export const yellowDark12 = "rgb(255, 250, 209)";
export const yellowDark2 = "rgb(34, 26, 0)";
export const yellowDark3 = "rgb(44, 33, 0)";
export const yellowDark4 = "rgb(53, 40, 0)";
export const yellowDark5 = "rgb(62, 48, 0)";
export const yellowDark6 = "rgb(73, 60, 0)";
export const yellowDark7 = "rgb(89, 74, 5)";
export const yellowDark8 = "rgb(112, 94, 0)";
export const yellowDark9 = "rgb(245, 217, 10)";